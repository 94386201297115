<template>
  <b-card>
    <form-modal
      ref="estateModalOffice"
      :formSchema="estatesFormSchema"
      title=" بيانات المستفيد"
      v-model="activeModel"
      :loading="loadingTerminationOfService"
      @confirm="onModalConfirmed"
    >
    </form-modal>

    <b-row v-if="currentVisitor">
      <h3>المستفيدين الحاليين</h3>

      <b-col cols="12">
        <BCard>
          <BRow
            v-for="(item, index) in currentVisitor"
            :key="index"
            class="border mb-1 p-1 rounded bg-light-success"
          >
            <BCol cols="3">
              <div class="h5">اسم المستفيد</div>
              <div>{{ item.beneficiary_name }}</div>
            </BCol>
            <BCol cols="3">
              <div class="h5">رقم الموبايل</div>
              <div>{{ item.phone_number }}</div>
            </BCol>
            <BCol cols="2">
              <div class="h5">تاريخ الميلاد</div>
              <div>{{ item.dob }}</div>
            </BCol>
            <BCol cols="1">
              <div class="h5">الجنس</div>
              <div>{{ item.gender === "M" ? "ذكر" : " أنثى" }}</div>
            </BCol>
            <BCol cols="3" class="d-flex">
              <div>
                <b-button
                  v-if="
                    authHelpers.hasRole('admin') ||
                    authHelpers.hasRole('reception-user')
                  "
                  variant="warning"
                  class="btn-icon mt-50 mx-1"
                  @click="
                    $router.push({
                      name: 'profile-beneficiary',
                      params: { id: item.beneficiary_id },
                    })
                  "
                >
                  <feather-icon icon="UserIcon" />
                </b-button>
              </div>
              <BButton
                @click="
                  activeModel = true;
                  idBeneficiary = item.id;
                  service_id = item.service_id;
                "
                variant="primary"
                class="mt-50"
              >
                إنهاء الخدمة
              </BButton>
            </BCol>
          </BRow>
        </BCard>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
const { BRow, BCard, BCol, BButton } = require("bootstrap-vue");
import FormModal from "@/components/FormModal.vue";
import { mapActions, mapGetters } from "vuex";
import { statusVisitor } from "@/utils/items-enam";
import authHelpers from "@/utils/auth-helpers";

export default {
  components: { BRow, BCard, BCol, BButton, FormModal },

  data: () => {
    return {
      activeModel: false,
      idBeneficiary: "",
      service_id: "",
      authHelpers: authHelpers,
      estatesFormSchema: {
        status: {
          component: "FormSelect",
          key: "status",
          attrs: {
            id: "status",
            label: "الحالة",
            rules: "required",
            reduce: (el) => el.value,
            options: statusVisitor,
          },
        },
        note: {
          component: "FormTextarea",
          key: "note",
          attrs: {
            label: "الملاحظات",
          },
        },
      },
    };
  },
  // TODO Add id_service to done service

  methods: {
    ...mapActions("manageCenter/office", ["TerminationOfService"]),
    onModalConfirmed(form) {
      this.TerminationOfService({
        ...form,
        visitor_id: this.idBeneficiary,
        service_id: this.service_id,
      }).then(() => {
        this.activeModel = false;
      });
    },
  },
  computed: {
    ...mapGetters("manageCenter/office", [
      "loadingTerminationOfService",
      "currentVisitorsLoading",
      "currentVisitor",
    ]),
  },
};
</script>
