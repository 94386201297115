<template>
  <b-card class="manage-jobs" v-if="id">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h3>المستفيدين</h3>
      <div>
        <b-row>
          <b-col cols="12" class="d-flex justify-content-end">
            <div class="d-flex justify-content-end">
              <b-button
                v-for="(item, i) in filterDate"
                :key="i"
                size="md"
                variant="outline-success"
                pill
                class="mr-50 ml-50 h-100"
                @click="status = item.status"
                :class="status == item.status ? 'bg-light-success' : ''"
                >{{ item.label }}</b-button
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <data-table
      ref="estatesTableee"
      :fields="fields"
      :ep="`/visitors-by-status/${id}?slug=${status}`"
    >
    <template #cell(purpose_of_visit)="item">
      {{ item.item.purpose_of_visit === "COS" ? 'اتمام خدمة' : 'خدمة جديدة'}}
    </template>
    </data-table>
  </b-card>
</template>

<script>
import DataTable from "@/components/data-table/index.vue";
import { BButton, BCol, BCard, BRow } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { BButton, BCol, BCard, BRow, DataTable },

  data: () => {
    return {
      status: "N",
      fields: [{ label: "Id", key: "id" },{ label: "اسم المستفيد", key: "beneficiary_name" },{ label: "رقم الموبايل", key: "phone_number" },{ label: "الغرض من الزيارة", key: "purpose_of_visit" },{ label: "الخدمة", key: "service.title" },{label:"الملاحظة",key:"notes"}],
      filterDate: [
        { id: 1, label: " الجدد", status: "N" },
        { id: 1, label: " المرفوضين  ", status: "R" },
        { id: 1, label: " المنجزة ", status: "D" },
      ],
      purposeVisitor : [
    { label: 'اتمام خدمة', value: 'COS' },
    { label: 'خدمة جديدة', value: 'NS' },
    { label: 'استفسار', value: 'I' },
    { label: 'زائر', value: 'V' },
]
    };
  },
  computed: {
    ...mapGetters("manageCenter/office", ["id"]),
  },
};
</script>
