<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <div class="flex">
        <!-- <b-button variant="primary" @click="onUpdateClicked">
                    أدخل بيانات المستفيد
                </b-button> -->
<!-- :disabled="loadingNextBeneficiarys || !available" -->
        <b-button
          variant="primary"
          class="mx-2"
          
          @click="nextBeneficiarys({ id: id })"
        >
          <div v-if="loadingNextBeneficiarys" class="row align-items-center">
            <b-spinner class="mx-50" small />
            <span> المستفيد التالي </span>
          </div>
          <div v-else>
            <feather-icon icon="UserIcon" class="mr-50" />
            <span> المستفيد التالي </span>
          </div>
        </b-button>
      </div>

      <div class="d-flex mt-50">
        <b-form-checkbox
          @change="changeStatus({ id: id })"
          :checked="available"
          class="custom-control-primary"
          name="check-button"
          switch
        />
        <h6>متاح</h6>
      </div>
    </div>
    <hr />

    <b-row>
      <!-- <b-col>
        <b-card class="border shadow-sm h-75">
          <h3>رقم البطاقة</h3>
          <hr />
          <h3 class="text-primary">{{ visitor_id }}</h3>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="border h-75">
          <h3>الخدمة</h3>
          <hr />
          <h3 class="text-primary">{{ service }}</h3>
        </b-card>
      </b-col> -->
      <b-col>
        <b-card class="border h-75">
          <h3>زوار بالإنتظار</h3>
          <hr />
          <h3 class="text-primary">{{ count_waiting }}</h3>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="border h-75">
          <h3>رقم المكتب</h3>
          <hr />
          <h3 class="text-primary">{{ nomberOffice }}</h3>
        </b-card>
      </b-col>
    </b-row>

    <CurrentVisitors />
    <hr/>
    <TableVisitorsOffice/>

  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BCardText,
  BSpinner,
  BDropdown,
  BDropdownItem
} from "bootstrap-vue";
import FormModal from "@/components/FormModal.vue";
import { mapActions, mapGetters } from "vuex";
import LoaderBtn from "@/components/loader-btn.vue";
import TableVisitorsOffice from './components/TableVisitorsOffice.vue'
import { statusVisitor } from "@/utils/items-enam";
import CurrentVisitors from "./components/CurrentVisitors.vue";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    FormModal,
    BFormCheckbox,
    BCardText,
    LoaderBtn,
    BSpinner,
    BDropdown,
    TableVisitorsOffice,
    BDropdownItem,
    CurrentVisitors
},
  data() {
    return {
    };
  },
  methods: {
    ...mapActions("manageCenter/office", [
      "getDataOffice",
      "changeStatus",
      "nextBeneficiarys",
      "checkStatus",
      "emptyDataOffice",
      "currentVisitors"

    ]),
    ...mapActions("manageCenter/manageVisitor", ["updateVisitor"]),
    onAdd() {
      this.$refs.estateModalOffice.init({});
      this.activModel = true;
    },

    onModalConfirmed(form) {
      if (form.id) {
        this.updateVisitor({
          id: form.id,
          fd: { ...form, cost: form.cost.replace(/\D/g, "") },
        }).then(() => {
          this.activModel = false;
          this.checkStatus();
          this.emptyDataOffice();
          this.getDataOffice();
        });
      }
    },
  },

  computed: {
    ...mapGetters("manageCenter/office", [
      "nomberOffice",
      "id",
      "loading",
      "loadingNextBeneficiarys",
      "visitor_id",
      "service",
      "count_waiting",
      "available",
    ]),
  },

  created() {
    this.getDataOffice();
  },
};
</script>
