var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('form-modal',{ref:"estateModalOffice",attrs:{"formSchema":_vm.estatesFormSchema,"title":" بيانات المستفيد","loading":_vm.loadingTerminationOfService},on:{"confirm":_vm.onModalConfirmed},model:{value:(_vm.activeModel),callback:function ($$v) {_vm.activeModel=$$v},expression:"activeModel"}}),(_vm.currentVisitor)?_c('b-row',[_c('h3',[_vm._v("المستفيدين الحاليين")]),_c('b-col',{attrs:{"cols":"12"}},[_c('BCard',_vm._l((_vm.currentVisitor),function(item,index){return _c('BRow',{key:index,staticClass:"border mb-1 p-1 rounded bg-light-success"},[_c('BCol',{attrs:{"cols":"3"}},[_c('div',{staticClass:"h5"},[_vm._v("اسم المستفيد")]),_c('div',[_vm._v(_vm._s(item.beneficiary_name))])]),_c('BCol',{attrs:{"cols":"3"}},[_c('div',{staticClass:"h5"},[_vm._v("رقم الموبايل")]),_c('div',[_vm._v(_vm._s(item.phone_number))])]),_c('BCol',{attrs:{"cols":"2"}},[_c('div',{staticClass:"h5"},[_vm._v("تاريخ الميلاد")]),_c('div',[_vm._v(_vm._s(item.dob))])]),_c('BCol',{attrs:{"cols":"1"}},[_c('div',{staticClass:"h5"},[_vm._v("الجنس")]),_c('div',[_vm._v(_vm._s(item.gender === "M" ? "ذكر" : " أنثى"))])]),_c('BCol',{staticClass:"d-flex",attrs:{"cols":"3"}},[_c('div',[(
                  _vm.authHelpers.hasRole('admin') ||
                  _vm.authHelpers.hasRole('reception-user')
                )?_c('b-button',{staticClass:"btn-icon mt-50 mx-1",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.$router.push({
                    name: 'profile-beneficiary',
                    params: { id: item.beneficiary_id },
                  })}}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1):_vm._e()],1),_c('BButton',{staticClass:"mt-50",attrs:{"variant":"primary"},on:{"click":function($event){_vm.activeModel = true;
                _vm.idBeneficiary = item.id;
                _vm.service_id = item.service_id;}}},[_vm._v(" إنهاء الخدمة ")])],1)],1)}),1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }